import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import HighlightsWrapper from '../components/highlights/HighlightsWrapper';

import { Collections } from '../@types/GraphQL';

interface HighlightsPageProps extends PageProps {
  data: {
    highlighted: {
      childMdx: {
        frontmatter: {
          highlighted_item?: string;
        };
      };
    };
    liveRecording?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const HighlightsPage: React.FC<HighlightsPageProps> = function (props) {
  const { data } = props;

  return (
    <Layout currPage={props.location.pathname}>
      <SEO title="Highlights | Courtney Barnett" />
      <HighlightsWrapper data={data} />
    </Layout>
  );
};

export default HighlightsPage;

export const pageQuery = graphql`
  query highlightsQuery {
    highlighted: file(sourceInstanceName: { eq: "highlighted" }) {
      childMdx {
        frontmatter {
          highlighted_item
        }
      }
    }
    liveRecording: allFile(
      filter: {
        sourceInstanceName: { eq: "live_recording" }
        childMdx: { frontmatter: { highlight: { eq: true } } }
      }
    ) {
      ...LiveRecording
    }
    liveVideo: allFile(
      filter: {
        sourceInstanceName: { eq: "live_video" }
        childMdx: { frontmatter: { highlight: { eq: true } } }
      }
    ) {
      ...LiveVideo
    }
    photo: allFile(
      filter: {
        sourceInstanceName: { eq: "photo" }
        childMdx: { frontmatter: { highlight: { eq: true } } }
      }
    ) {
      ...Photo
    }
    polaroid: allFile(
      filter: {
        sourceInstanceName: { eq: "polaroid" }
        childMdx: { frontmatter: { highlight: { eq: true } } }
      }
    ) {
      ...Polaroid
    }
    poster: allFile(
      filter: {
        sourceInstanceName: { eq: "poster" }
        childMdx: { frontmatter: { highlight: { eq: true } } }
      }
    ) {
      ...Poster
    }
    text: allFile(
      filter: {
        sourceInstanceName: { eq: "text" }
        childMdx: { frontmatter: { highlight: { eq: true } } }
      }
    ) {
      ...Text
    }
    show: allFile(filter: { sourceInstanceName: { eq: "show" } }) {
      ...Show
    }
  }
`;
